export const NavbarData = [
  {
    url: {
      url: 'C',
      link: 'a',
    },
    id: 1,
    navItem: 'Business Verticals',
    link: '/BusinessVerticals',
    navItems: [
      {
        url: {
          url: 'https://kalfreight.com/',
          link: 'a',
        },
        id: 1,
        navItem: 'KAL Freight',
      },
      {
        url: {
          url: 'https://kaltrailers.com/',
          link: 'a',
        },

        id: 2,
        navItem: 'KAL Trailers & Leasing',
      },
      {
        url: {
          url: 'https://kaltires.com/',
          link: 'a',
        },
        id: 3,
        navItem: 'KVL Tires',
      },
      {
        url: {
          url: 'https://kalpartz.com/',
          link: 'a',
        },
        id: 4,
        navItem: 'KAL Partz',
      },
      {
        url: {
          url: 'https://kalway.com/',
          link: 'a',
        },
        id: 5,
        navItem: 'KALWAY',
      },
    ],
  },
  {
    url: {
      url: 'C',
      link: 'a',
    },
    id: 2,
    navItem: 'About Us',
    link: '/BusinessVerticals',
    navItems: [
      {
        url: {
          url: 'AboutUs-Scroll',
          link: 's',
        },
        id: 1,
        navItem: 'Company Overview',
      },
      {
        url: {
          url: 'https://kalfreight.com/who-we-are/csr',
          link: 'a',
        },
        id: 2,
        navItem: 'CSR',
      },
    ],
  },
  {
    url: {
      url: 'C',
      link: 'a',
    },
    id: 3,
    navItem: 'Newsroom',
    link: '/BusinessVerticals',
    navItems: [
      {
        url: {
          url: 'NewsRoom',
          link: 's',
        },
        id: 1,
        navItem: 'Events',
      },
      {
        url: {
          url: 'NewsRoom',
          link: 's',
        },
        id: 2,
        navItem: 'Blog',
      },
    ],
  },
  {
    url: {
      url: 'C',
      link: 'a',
    },
    id: 4,
    navItem: 'Services',
    link: '/BusinessVerticals',
    navItems: [
      {
        url: {
          url: 'https://kalfreight.com/what-we-do/dry-van',
          link: 'a',
        },
        id: 1,
        navItem: 'Logistics',

        navItems: [
          {
            url: {
              url: 'https://kalfreight.com/what-we-do/FTL-services',
              link: 'a',
            },
            id: 1,
            navItem: 'FTL Services',
          },
          {
            url: {
              url: 'https://kalfreight.com/what-we-do/dedicated-loads',
              link: 'a',
            },
            id: 2,
            navItem: 'Dedicated Loads',
          },
          {
            url: {
              url: 'https://kalfreight.com/what-we-do/dry-van',
              link: 'a',
            },
            id: 3,
            navItem: 'Dry Vans',
          },
          {
            url: {
              url: 'https://kalfreight.com/what-we-do/trailer-interchange',
              link: 'a',
            },
            id: 4,
            navItem: 'Trailer Interchange',
          },
        ],
      },
      {
        url: {
          url: 'https://kalpartz.com/products/',
          link: 'a',
        },
        id: 2,
        navItem: 'Partz',
        navItems: [
          {
            url: {
              url: 'https://kalpartz.com/products/',
              link: 'a',
            },
            id: 1,
            navItem: 'O.E & O.E.M Parts',
          },
          {
            url: {
              url: 'https://kalpartz.com/products/',
              link: 'a',
            },
            id: 2,
            navItem: 'Custom Parts & Accessories',
          },
          {
            url: {
              url: 'https://kalpartz.com/products/',
              link: 'a',
            },
            id: 3,
            navItem: 'Aftermarket Parts',
          },
        ],
      },
      {
        url: {
          url: 'http://kaltires.com/#Advantages',
          link: 'a',
        },
        id: 3,
        navItem: 'Tires',
        navItems: [
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 1,
            navItem: '24x7 Roadside Assistance',
          },
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 2,
            navItem: 'Tire Mounting & Balancing',
          },
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 3,
            navItem: 'Wheel Alignment',
          },
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 4,
            navItem: 'Heavy Duty Front-end Work',
          },
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 5,
            navItem: 'Scheduled Maintenance',
          },
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 6,
            navItem: 'On Site Service',
          },
          {
            url: {
              url: 'http://kaltires.com/#Advantages',
              link: 'a',
            },
            id: 7,
            navItem: 'Pick up and Delivery Service',
          },
        ],
      },

      {
        url: {
          url: 'C',
          link: 'a',
        },
        id: 4,
        navItem: 'Trailers & Leasing',
        navItems: [
          {
            url: {
              url: 'https://kaltrailers.com/product-category/trucks/used-trucks/',
              link: 'a',
            },
            id: 1,
            navItem: 'Used Trucks Sale & Purchase',
          },
          {
            url: {
              url: 'https://kaltrailers.com/product-category/trailers/new-trailers/',
              link: 'a',
            },
            id: 2,
            navItem: 'New Trailers Sale & Purchase',
          },
        ],
      },
      {
        url: {
          url: 'https://kaltrailers.com/credit-application/',
          link: 'a',
        },
        id: 5,
        navItem: 'Financial Solutions',
      },
      {
        url: {
          url: 'ContactSection',
          link: 'a',
        },
        id: 6,
        navItem: 'Brokerage',
        navItems: [
          {
            url: {
              url: 'https://kalway.com/services/#Products',
              link: 'a',
            },
            id: 1,
            navItem: '3PL Service ',
          },
          {
            url: {
              url: 'https://kalway.com/Carriers',
              link: 'a',
            },
            id: 2,
            navItem: 'Load Management',
          },
          {
            url: {
              url: 'https://kalway.com/services/#Products',
              link: 'a',
            },
            id: 3,
            navItem: 'Full Truck Service',
          },
        ],
      },

      {
        url: {
          url: 'https://kalfreight.com/drivers',
          link: 'a',
        },
        id: 7,
        navItem: 'Driver Assistance',
      },

      {
        url: {
          url: 'https://kaltrailers.com/credit-application/',
          link: 'a',
        },
        id: 8,
        navItem: 'Customer Support',
      },
    ],
  },
  {
    url: {
      url: 'ContactSection',
      link: 's',
    },
    id: 3,
    navItem: 'Careers',

    // link: "/BusinessVerticals",
    // navItems: [
    //   {
    //     id: 1,
    //     navItem: "Kal Freight",
    //   },
    //   {
    //     id: 2,
    //     navItem: "Kal Trailers",
    //   },
    // ],
  },
];
export const NewsData = [
  {
    id: '1',
    category: 'News',
    date: '15th & 16th April 2022',
    heading: 'American Trucking Show',
    PhotoHeading: 'American Trucking Show',
    imageUrl:
      'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/News/americatrucking.png',
    desc: 'KAL Group made a successful appearance at American Trucking Show, Fresno',
    CTA: { heading: 'See More', link: 'contactus' },
    Tag: 'Fresno',
  },
  {
    id: '2',
    category: 'News',
    date: '24th April 2022',
    heading: 'Atlanta Kabaddi Cup',
    PhotoHeading: 'Atlanta Kabaddi Cup',
    imageUrl:
      'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/News/kabbdi.png',
    desc: ' KAL Group was one of the sponsored Atlanta Kabaddi Cup',
    CTA: { heading: 'See More', link: 'contactus' },
    Tag: 'Atlanta',
  },
  // {
  //   id: '3',
  //   category: 'News',
  //   date: '01/06/2002',
  //   heading: 'Indiana Truck Show',
  //   PhotoHeading: 'KAL Group Dinner',
  //   imageUrl:
  //     'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/news.png',
  //   desc: "It doesn't matter if you love Peterbilts, Western Stars, Macks, or if a Kenworth turns your crank, there are lots of these cool trucks to see at a truck show.",
  //   CTA: { heading: 'See More', link: 'contactus' },
  //   Tag: 'One click away',
  // },
  // {
  //   id: '4',
  //   category: 'News',
  //   date: '01/06/2002',
  //   heading: 'Indiana Truck Show',
  //   PhotoHeading: 'KAL Group Dinner',
  //   imageUrl:
  //     'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/news.png',
  //   desc: "It doesn't matter if you love Peterbilts, Western Stars, Macks, or if a Kenworth turns your crank, there are lots of these cool trucks to see at a truck show.",
  //   CTA: { heading: 'See More', link: 'contactus' },
  //   Tag: 'One click away',
  // },
];
export const EventsData = [
  {
    id: '1',
    category: 'Upcoming Events',
    date: '25th & 26th June 2022',
    heading: 'Texas Trucking Show',
    PhotoHeading: 'Texas Trucking Show',
    imageUrl:
      'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/Event/Texas.jpg',
    desc: 'Texas Trucking Show, Join us at NRG Center, Texas',

    desc2: ` Reserved a seat for `,
    desc3: `"Dinner with the KAL group"`,

    desc4: `Early Bird registration click below`,
    CTA: { heading: 'Register', link: 'register' },
    Tag: 'Texas',
  },
  {
    id: '2',
    category: 'Upcoming Events',
    date: 'Coming Soon',
    heading: 'California Trucking Show ',
    PhotoHeading: 'California Trucking Show ',
    imageUrl:
      'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/Event/Ontario-Upcomiing.png',
    desc: `   California Trucking Show at the Ontario Convention Center coming this October.  

    
    
     
    
    `,

    desc2: ` Reserved a seat for `,
    desc3: `"Dinner with the KAL group"`,

    desc4: `Early Bird registration click below`,
    CTA: { heading: 'Register', link: 'ContactSection' },
    Tag: 'Ontario',
  },
  // {
  //   id: '3',
  //   category: 'Upcoming events',
  //   date: '01/06/2002',
  //   heading: 'Come to our dinner',
  //   PhotoHeading: 'KAL Group Dinner',
  //   imageUrl:
  //     'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/KalGroupEvents/kalevent1.png',
  //   desc: "We provide a full range of front end mechanical repairs for all makes and models of car It doesn't matter if you love Peterbilts.",
  //   CTA: { heading: 'Register', link: 'register' },
  //   Tag: 'One click away',
  // },
  // {
  //   id: '4',
  //   category: 'Upcoming events',
  //   date: '01/06/2002',
  //   heading: 'Come to our Show',
  //   PhotoHeading: 'KAL Group Dinner',
  //   imageUrl:
  //     'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/KalGroupEvents/kalevent1.png',
  //   desc: "We provide a full range of front end mechanical repairs for all makes and models of car It doesn't matter if you love Peterbilts.",
  //   CTA: { heading: 'Register', link: 'register' },
  //   Tag: 'One click away',
  // },
];
export const HomeSliderData = [
  {
    id: 1,
    site: { heading: 'Freight', Color: null },
    Heading: 'Delivering Commitment since 2014',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique arcu a nisi, semper. Ut cras odio ac sem ac.',

    CTA: { heading: 'Contact', link: 'contactus' },
  },
  {
    id: 2,
    site: { heading: 'Freight', Color: '#8BD100' },
    Heading: 'Delivering Commitment since 2014',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique arcu a nisi, semper. Ut cras odio ac sem ac.',

    CTA: { heading: 'Contact', link: 'contactus' },
  },
  {
    id: 3,
    site: { heading: 'Tires', Color: '#EC2227' },
    Heading: 'Delivering Commitment since 2014',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique arcu a nisi, semper. Ut cras odio ac sem ac.',

    CTA: { heading: 'Contact', link: 'contactus' },
  },
  {
    id: 4,
    site: { heading: 'Partz', Color: '#F15A22' },
    Heading: 'Delivering Commitment since 2014',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique arcu a nisi, semper. Ut cras odio ac sem ac.',

    CTA: { heading: 'Contact', link: 'contactus' },
  },
];
export const ServiceComponentdata = [
  {
    Heading: 'Our Services',
    Desc: 'Kal Tires has 9 distribution centers, 4 service centers and nationwide 24/7 emergency road service. Exclusive with major tire manufactures such as Goodyear, Cooper, BKT, Michelin, Yokohama, Double Coin, Pirelli and Hankook. We offer national account billing for Goodyear, Cooper, Michelin, Yokohama and Hankook products.  ',
    CTA: { heading: 'CONTACT US', link: 'contactus' },
    services: [
      {
        id: 1,
        Logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/b14fcada80e930a65933829f228e72d2de3bc620/src/assets/Images/icons/TruckAndTrailers-icon.svg',
        Heading: 'Trailers & Leasing',
        desc: `BigRig Trailers & Leasing Inc is a licensed distributor for Vanguard trailers. We have a diverse inventory of dry vans, reefers, flatbeds, heated dry vans and used trucks. The dry vans are available in 24" and 48"  logistic posts.   Our reefer's inventory includes top-grade units from brands like Thermo King, Carrier, and more.`,
      },
      {
        id: 2,
        Logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/b14fcada80e930a65933829f228e72d2de3bc620/src/assets/Images/icons/partz-icon.svg',
        Heading: 'Partz',
        desc: 'BigRig Partz is the fastest-growing OE & OEM aftermarket parts supplier for commercial vehicles. The comprehensive inventory covers all makes & models from 100+ brands to cater to every demand of your commercial vehicle. In addition, we have partnered with tier 1 and tier 2 parts suppliers to deliver genuine products.  ',
      },
      {
        id: 3,
        Logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/b14fcada80e930a65933829f228e72d2de3bc620/src/assets/Images/icons/tire-icon.svg',
        Heading: 'Tires & Services',
        desc: 'Designed To Reduce Your Cost Of Ownership, Our Trucks Improved Fuel Economy, Enhanced Safety Features And Lead The Way In Technological Advancements And Driver Comfort.',
      },
    ],
  },
];

export const GroupWebsiteComponentdata = [
  {
    id: '1',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/TrailersLeasing.png',
    Logourl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/TrailersLeasingLogo.png',
    icon: 'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/icons/TruckAndTrailers-icon.png',
    heading: 'Trailers & Leasing',
    desc: [
      {
        id: 1,
        // head: `Trucks`,
        desc: `BigRig Trailers & Leasing Inc is a licensed distributor for Vanguard trailers. We have a diverse inventory of dry vans, reefers, flatbeds, heated dry vans and used trucks. The dry vans are available in 24" and 48" logistic posts. Our reefer's inventory includes top-grade units from Thermo King and Carrier brands.  `,
      },
      {
        id: 2,
        // head: `Trailers`,
        desc: `We have quality used trailers for sale with various makes from Utility, Wabash, Stoughton, and Great Dane. We have all brands in used trucks. `,
      },
      {
        id: 3,
        // head: `Trailers`,
        desc: `To cater to your trailer & truck financing needs, we have a marvellous team of professionals by our side to ensure a hassle-free process. We endeavour to offer a smooth customer experience and guaranteed satisfaction.  `,
      },
      {
        id: 4,
        // head: `Trailers`,
        desc1: [
          {
            head: 'For more information, please connect with our sales representative.',
            desc: [
              {
                id: 1,
                // head: `Trucks`,
                desc: `Phone: 800-977-0010  `,
              },
              {
                id: 2,
                // head: `Trailers`,
                desc: `Email: trailers@bigrigcanada.com `,
              },
            ],
          },
        ],

        // `For more information, please connect with our sales representative.
        // Phone: 800-977-0010

        // Email: trailers@bigrigcanada.com

        // For Financial solutions:  `,
      },
    ],
    cta: {
      heading: 'Request a quote',
      link: 'Inventory',
    },
  },
  {
    id: '2',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/Partz.png',
    Logourl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigpartzlogo.png',
    icon: 'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/icons/partz-icon.png',
    heading: 'Partz ',
    desc: [
      {
        id: 1,
        // head: `Heavy Duty Parts`,
        desc: `BigRig Partz is the fastest-growing OE & OEM aftermarket parts supplier for commercial vehicles. The comprehensive inventory covers all makes & models from 100+ brands to cater to every demand of your commercial vehicle. In addition, we have partnered with tier 1 and tier 2 parts suppliers to deliver genuine products.  `,
        // desc2: `Our “Culturally Diverse” Parts Professionals around the country are able to assist and provide outstanding customer service and break through any language barrier.`,
      },

      {
        id: 2,
        // head: `Trailers`,
        desc1: [
          {
            head: 'For more information, please get in touch with our parts experts.',
            desc: [
              {
                id: 1,
                // head: `Trucks`,
                desc: `   Phone: 800-977-0010   `,
              },
              {
                id: 2,
                // head: `Trailers`,
                desc: ` Email: partz@bigrigcanada.com `,
              },
            ],
          },
        ],

        // `For more information, please connect with our sales representative.
        // Phone: 800-977-0010

        // Email: trailers@bigrigcanada.com

        // For Financial solutions:  `,
      },
    ],
    cta: {
      heading: 'Check our inventory',
      link: 'Inventory',
    },
    comingsoon: false,
  },
  {
    id: '3',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigtire.png',
    Logourl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigtirelogo.png',
    icon: 'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/icons/tire-icon.png',
    heading: 'Tires & Services',
    desc: [
      {
        id: 1,
        // head: `Commercial Tires`,
        desc: `BigRig Tires & Services is the fastest growing distributor of commercial, industrial, and agricultural tires. BKT, Prinx Tires, Pirelli, and Cooper are notable brands offered.`,
      },
      {
        id: 2,
        // head: `24/7  Road service`,
        desc: `We have an excellent team of qualified technicians performing after-sales services, such as retread services, flat repairs, tire balancing, and heavy-duty wheel alignments in our well-equipped facilities. In addition, we offer world-class ringtread services in partnership with Marangoni Tread North America, Inc. `,
      },
      // {
      //   id: 3,
      //   // head: `24/7  Road service`,
      //   desc: `For more information, please get in touch with our tire enthusiasts.

      //   Phone: 800-977-0010

      //   Email: tires@bigrigcanada.com `,
      // },
      {
        id: 3,
        // head: `Trailers`,
        desc1: [
          {
            head: 'For more information, please get in touch with our tire enthusiasts.',
            desc: [
              {
                id: 1,
                // head: `Trucks`,
                desc: `Phone: 800-977-0010`,
              },
              {
                id: 2,
                // head: `Trailers`,
                desc: `Email: tires@bigrigcanada.com`,
              },
            ],
          },
        ],

        // `For more information, please connect with our sales representative.
        // Phone: 800-977-0010

        // Email: trailers@bigrigcanada.com

        // For Financial solutions:  `,
      },
    ],
    cta: {
      heading: 'Inquire Now',
      link: 'Inventory',
    },
    comingsoon: false,
  },
];

export const BrandCompnentBrands = [
  {
    id: '1',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/Freightliner-logo.png',
    heading: 'Freightliner',
  },
  {
    id: '2',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/Kenworth-logo.png',
    heading: 'Kenworth',
  },
  {
    id: '3',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/Peterbilt-logo.png ',
    heading: 'Peterbilt',
  },
  {
    id: '4',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/Vanguard-logo.png',
    heading: 'Vanguard',
  },
  {
    id: '5',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/Wix_Filters-logo.png',
    heading: 'Wix Filters',
  },
  {
    id: '6',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/bendix-logo.png',
    heading: 'Bendix',
  },
  // {
  //   id: "7",
  //   imageurl:
  //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/Volvo-logo.png",
  //   heading: "Volvo",
  // },

  {
    id: '9',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BrandsLogo/havco-logo.png',
    heading: 'Havco',
  },
  {
    id: '10',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BrandsLogo/Thermoking%20Logo.png',
    heading: 'Thermoking',
  },
  {
    id: '11',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BrandsLogo/carrier%20logo.png',
    heading: 'Havco',
  },
  {
    id: '12',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BrandsLogo/og-iron-mark.jpg',
    heading: 'Havco',
  },
  {
    id: '13',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/pirelli.png',
    heading: 'Havco',
  },
  {
    id: '14',
    imageurl:
      'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/prinx.png',
    heading: 'Havco',
  },

  {
    id: '15',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/Atro-es.png',
    heading: 'Atro-es',
  },
  {
    id: '16',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/Automann.png',
    heading: 'Automann',
  },
  {
    id: '17',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/FCS-Auto.png',
    heading: 'FCS-Auto',
  },
  {
    id: '18',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/SAF-holand.png',
    heading: 'SAF-holand',
  },
  {
    id: '19',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/alliant-power.png',
    heading: 'alliant-power',
  },
  {
    id: '20',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/armorall.png',
    heading: 'armorall',
  },

  {
    id: '21',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/canatrux.png',
    heading: 'canatrux',
  },
  {
    id: '22',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/cbluo-DEF.png',
    heading: 'cbluo-DEF',
  },
  {
    id: '23',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/cold-cranker.png',
    heading: 'cold-cranker',
  },
  {
    id: '24',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/crc-barkleen.png',
    heading: 'crc-barkleen',
  },
  {
    id: '25',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/crc-logo.png',
    heading: 'crc-logo',
  },
  {
    id: '26',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/cummins.png',
    heading: 'cummins',
  },
  {
    id: '27',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/delco-remy.png',
    heading: 'delco-remy.png',
  },
  {
    id: '28',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/donaldson.png',
    heading: 'donaldson',
  },
  {
    id: '29',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/dura-lite.png',
    heading: 'dura-lite',
  },
  {
    id: '30',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/eaton.png',
    heading: 'eaton',
  },
  // {
  //   id: '31',
  //   imageurl:
  //     'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/fleetguard.png',
  //   heading: 'fleetguard',
  // },
  {
    id: '32',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/haldex.png',
    heading: 'haldex',
  },
  {
    id: '33',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/magnum-bumper.png',
    heading: 'magnum-bumper',
  },
  {
    id: '34',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/monroe.png',
    heading: 'monroe',
  },
  {
    id: '35',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/nemco.png',
    heading: 'nemco',
  },
  {
    id: '36',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/permatex.png',
    heading: 'permatex',
  },
  {
    id: '37',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/sampa.png',
    heading: 'sampa',
  },
  {
    id: '38',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/shell.png',
    heading: 'shell',
  },
  {
    id: '39',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/skf.png',
    heading: 'skf',
  },
  {
    id: '40',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/speed-demon-lights.png',
    heading: 'speed-demon-lights',
  },
  {
    id: '41',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/stemco.png',
    heading: 'stemco',
  },
  {
    id: '42',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/timken.png',
    heading: 'timken',
  },
  {
    id: '43',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/vanguard-cimc.png',
    heading: 'vanguard-cimc',
  },
  {
    id: '44',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/vanguard.png',
    heading: 'vanguard',
  },
  {
    id: '45',
    imageurl:
      'https://raw.githubusercontent.com/Kalfreight-In/kalgroup/main/src/assets/Images/BigRigpartzSponsers/wd-40.png',
    heading: 'wd-40',
  },
];
export const OurMajorClient = [
  {
    id: '0',
    logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigpartzlogo.png',
    name: 'Our Major Client',
    desc: 'Our Major Client',
    Brands: [
      {
        id: '25',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/haldex.png',
        heading: 'haldex',
      },
      {
        id: '28',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/kinedyne.png',
        heading: 'kinedyne',
      },
      {
        id: '36',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/paccar.png',
        heading: 'paccar',
      },
      {
        id: '50',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/sns-truck.png',
        heading: 'sns-truck',
      },
      {
        id: '53',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BrandsLogo/webb.png',
        heading: 'total',
      },
      {
        id: '53',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/total.png',
        heading: 'total',
      },
    ],
  },
];

export const BigPArtzBrands = [
  {
    id: '0',
    logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigpartzlogo.png',
    name: 'Kalpartz',
    desc: 'With an extensive inventory of 50000+ parts, our experts always keep you on the move. ',
    Brands: [
      {
        id: '1',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/alliant-power.png',
        heading: 'alliant-power',
      },
      {
        id: '2',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/american-forge-foundry.png',
        heading: 'american-forge-foundry',
      },
      {
        id: '3',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/amsted-seals.png',
        heading: 'amsted-seals',
      },
      {
        id: '4',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/Atro-es.png',
        heading: 'Atroe-es',
      },

      {
        id: '5',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/Automann.png',
        heading: 'Automann',
      },
      {
        id: '6',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/bbs.png',
        heading: 'bbs',
      },
      {
        id: '7',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/black.png',
        heading: 'black.png',
      },
      {
        id: '8',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/bluesky.png',
        heading: 'bluesky',
      },
      {
        id: '9',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/bremskerl.png',
        heading: 'bremskerl',
      },
      {
        id: '10',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/buffers.png',
        heading: 'buffers',
      },
      {
        id: '11',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/dayco.png',
        heading: 'dayco',
      },
      {
        id: '12',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/dayton-parts.png',
        heading: 'dayton-parts',
      },

      {
        id: '13',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/denso.png',
        heading: 'denso',
      },
      {
        id: '14',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/dorman.png',
        heading: 'dorman',
      },
      {
        id: '15',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/dpfgasket.png',
        heading: 'dpfgasket',
      },
      {
        id: '16',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/emp.png',
        heading: 'emp',
      },
      {
        id: '17',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/everco.png',
        heading: 'everco',
      },
      {
        id: '18',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/eyman.png',
        heading: 'eyman',
      },
      {
        id: '19',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/gates.png',
        heading: 'gates',
      },
      {
        id: '20',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/goldenstate.png',
        heading: 'goldenstate',
      },
      {
        id: '21',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/goodyear.png',
        heading: 'goodyear',
      },
      {
        id: '22',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/grand-rock.png',
        heading: 'grand-rock',
      },
      {
        id: '23',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/grote.png',
        heading: 'grote',
      },
      {
        id: '24',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/grote-lighting.png',
        heading: 'grote-lighting',
      },
      {
        id: '25',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/haldex.png',
        heading: 'haldex',
      },
      {
        id: '26',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/insulated-transport.png',
        heading: 'insulated-transport',
      },
      {
        id: '27',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/interstate.png',
        heading: 'interstate',
      },
      {
        id: '28',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/kinedyne.png',
        heading: 'kinedyne',
      },
      {
        id: '29',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/lucas.png',
        heading: 'lucas',
      },
      {
        id: '30',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/majestic.png',
        heading: 'majestic',
      },
      {
        id: '31',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/merritt.png',
        heading: 'merritt',
      },
      {
        id: '32',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/mid-america.png',
        heading: 'mid-america',
      },
      {
        id: '33',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/midwest.png',
        heading: 'midwest',
      },
      {
        id: '34',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/mule.png',
        heading: 'mule',
      },
      {
        id: '35',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/na-williams.png',
        heading: 'na-williams',
      },
      {
        id: '36',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/paccar.png',
        heading: 'paccar',
      },
      {
        id: '37',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/pai.png',
        heading: 'pai',
      },
      {
        id: '38',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/philatron.png',
        heading: 'philatron',
      },
      {
        id: '39',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/premier.png',
        heading: 'premier',
      },
      {
        id: '40',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/prochem.png',
        heading: 'prochem',
      },
      {
        id: '41',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/prt.png',
        heading: 'prt',
      },
      {
        id: '42',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/revhd.png',
        heading: 'revhd',
      },
      {
        id: '43',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/right-weigh.png',
        heading: 'right-weigh',
      },
      {
        id: '44',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/roadmaster.png',
        heading: 'roadmaster',
      },
      {
        id: '45',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/rush-truck-centers.png',
        heading: 'rush-truck-center',
      },
      {
        id: '46',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/SAF-holand.png',
        heading: 'SAF-holand',
      },
      {
        id: '47',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/sampa.png',
        heading: 'sampa',
      },
      {
        id: '48',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/sas-safety.png',
        heading: 'sas-safety',
      },
      {
        id: '49',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/smp.png',
        heading: 'smp',
      },
      {
        id: '50',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/sns-truck.png',
        heading: 'sns-truck',
      },
      {
        id: '51',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/sonsary.png',
        heading: 'sonsary',
      },
      {
        id: '52',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/torque-parts.png',
        heading: 'torque-parts',
      },
      {
        id: '53',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/total.png',
        heading: 'total',
      },
      {
        id: '54',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/trico.png',
        heading: 'trico',
      },
      {
        id: '55',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/tsm.png',
        heading: 'tsm',
      },
      {
        id: '56',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/ts-tramecsolan.png',
        heading: 'ts-tramecsolan',
      },
      {
        id: '57',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/valvoline.png',
        heading: 'valvoline',
      },
      {
        id: '58',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/whiting.png',
        heading: 'whiting',
      },
      {
        id: '59',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigpartzSponsers/wix.png',
        heading: 'wix',
      },
    ],
  },
];

export const BigTireBrands = [
  {
    id: '0',
    logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/tirered.svg',
    name: 'Kaltire',
    desc: 'Top-notch quality tires and best-in-class services are just a call away.  ',
    Brands: [
      {
        id: '2',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/pirelli.png',
        heading: 'Pirelli',
      },

      {
        id: '3',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/michelin.png',
        heading: 'michelin',
      },

      {
        id: '4',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/general-tire.png',
        heading: 'general-tire',
      },
      {
        id: '5',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/cooper.png',
        heading: 'Havco',
      },
      {
        id: '6',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/continental.png',
        heading: 'continental',
      },
      {
        id: '7',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/bkt.png',
        heading: 'bkt',
      },

      {
        id: '8',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/apollo.png',
        heading: 'apollo',
      },

      {
        id: '9',
        imageurl:
          'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/BigRigTireSponsers/ameri.png',
        heading: 'ameri',
      },
      // {
      //   id: "15",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/prinx.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "16",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/marangoni.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "2",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/cooper.png",
      //   heading: "CT_Horiz",
      // },

      // {
      //   id: "9",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/BKT-logo.png",
      //   heading: "Havco",
      // },

      // {
      //   id: "14",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/pirelli.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "15",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/prinx.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "16",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/marangoni.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "2",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/cooper.png",
      //   heading: "CT_Horiz",
      // },

      // {
      //   id: "9",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/BKT-logo.png",
      //   heading: "Havco",
      // },

      // {
      //   id: "14",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/pirelli.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "15",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/prinx.png",
      //   heading: "Havco",
      // },
      // {
      //   id: "16",
      //   imageurl:
      //     "https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/BigRigTireSponsers/marangoni.png",
      //   heading: "Havco",
      // },
    ],
  },
];

export const AboutusSec = [
  {
    id: '0',
    headingmain: 'About Us',
    desc: `Established in 2021, Kal Group has a unique business portfolio that fulfills all trucking needs under one roof, making it one of its own kind in the industry. Kal Group is an end-to-end transportation solution provider in Western Canada. We have designed distinctive business models to cater to every type of trucking requirement. `,
    aboutimg: [
      {
        id: '1',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigrigtrailerlogo.svg',
        heading: 'trailer',
      },
      {
        id: '2',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigrigpartzlogo.svg',
        heading: 'partz',
      },
      {
        id: '3',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/bigrigtireslog.svg',
      },
    ],
  },
];

export const Businessdata = [
  {
    id: '0',
    heading: 'Our Business Verticals',
    thebox: [
      {
        id: '4',
        url: 'https://kalfreight.com/',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Sfreight.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/freight.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstirelogo.png',
        heading: 'LOOKING FOR TIRES IS NOT A PROBLEM NOW',
        desc: 'Contact Us for the best trucks and trailers',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Freight',
      },
      {
        id: '1',
        url: 'https://kaltrailers.com/',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Strailers.png',
        Bimg: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/icons/trailers.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstrllogo.png',
        heading: 'GET ON THE ROAD WITH POWERFUL ENGINE',
        desc: 'Contact Us for the best trucks and trailers',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Trailer & Leasing',
      },
      {
        id: '3',
        url: 'http://kaltires.com/',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Stires.png',
        Bimg: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/icons/tires.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstirelogo.png',
        heading: 'LOOKING FOR TIRES IS NOT A PROBLEM NOW',
        desc: 'Contact Us for the best trucks and trailers',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Tires & Services',
      },
      {
        id: '2',
        url: 'https://kalpartz.com/',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Sparts.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Bparts.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesspartzlogo.png',
        heading: 'LAST LONG WITH THE BEST PARTS ON THE MARKET',
        desc: 'We have the best brands to get your truck on the road',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstools.png',
        btn: 'Parts & services',
      },

      {
        id: '4',
        url: 'https://kalway.com/',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/Skalway.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/icons/kalway.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstirelogo.png',
        heading: 'LOOKING FOR TIRES IS NOT A PROBLEM NOW',
        desc: 'Contact Us for the best trucks and trailers',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Freight',
      },
    ],
  },
];

export const CoreValuedata = [
  {
    id: '0',
    heading: 'Our Core Values',
    thebox: [
      {
        id: '1',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/CoreValue-mb.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/precision.png',

        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstrllogo.png',
        heading: 'Precision',
        desc: 'We create a transparent structure that anyone can rely on. We define growth with accuracy and loyalty to foster an open culture where anyone can feel connected.  ',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Trailer & Leasing',
      },
      {
        id: '2',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/CoreValue-mb.png',
        Bimg: ' https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/reliability.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesspartzlogo.png',
        heading: 'Reliability',
        desc: 'We strive for excellence in everything we do. We believe in delivering excellence through performance, innovation, and product quality.  ',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstools.png',
        btn: 'Parts & services',
      },
      {
        id: '3',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/CoreValue-mb.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/ethical.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstirelogo.png',
        heading: 'Ethics',
        desc: 'We honor our commitments to our customers and co-workers to do the right thing and are resolute in Integrity. ',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Tires & Services',
      },
      {
        id: '4',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/CoreValue-mb.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/collobration.png',

        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstirelogo.png',
        heading: 'Collaboration',
        desc: 'We value our clients as partners to foster the spirit of “we before me”, and we believe in building long-term relationships.',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Tires & Services',
      },
      {
        id: '5',
        Simg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/CoreValue-mb.png',
        Bimg: 'https://raw.githubusercontent.com/Kalfreight-In/Kalgroup/main/src/assets/Images/CoreValues/customer-focus.png',
        logo: 'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstirelogo.png',
        heading: 'Customer Focus',
        desc: 'We go to the extra mile to deliver “Customer Delight” to ensure exceptional service and support. No wonder, we are the trusted choice of our customers.',
        logotwo:
          'https://raw.githubusercontent.com/kalfreight-in/kalgroup/master/src/assets/Images/busnesection/businesstires.png',
        btn: 'Tires & Services',
      },
    ],
  },
];

export const Facbookfeeds = [
  {
    id: '1',
    caption: 'Used Trailers',
    desc: 'New and Used Trailers, Dry Vans and Reefers are at KAL! We are one of the largest authorized dealers for Vanguard trailers in the United States. Flexible in-house financing/leasing options! ',
    time: '7 June',
    follow: 'https://www.facebook.com/kalgroupusa/?ref=page_internal',
  },
  {
    id: '2',
    caption: 'KVL Tires',
    desc: 'KVL Tires proudly carries the General HS 2 - a tire that offers optimal performance and a comfortable ride, without compromising on fuel efficiency. ',
    time: '21 May',
    follow: 'https://www.facebook.com/kalgroupusa/?ref=page_internal',
  },

  {
    id: '3',
    caption: 'KalPartz',
    desc: 'KAL Partz now offers Amsted Seals wheel-end products; designed to ALL work together - to fit right, install easily and perform at their best! Our team is available to simplify the selection, buying and stocking process for you. Contact us today for more details! ',
    time: '12 May',
    follow: 'https://www.facebook.com/kalgroupusa/?ref=page_internal',
  },
  {
    id: '4',
    caption: 'Kalway',
    desc: 'Our KALWAY team has designed a freight program for those interested in lease-to-own with GUARANTEED LOADS. Open Enrollment! For more information or to apply please call: 1-800-303-0076',
    time: '2 May',
    follow: 'https://www.facebook.com/kalgroupusa/?ref=page_internal',
  },
];

export const Instagramfeeds = [
  {
    id: '1',
    img: 'https://scontent-maa2-1.xx.fbcdn.net/v/t39.30808-6/286169510_519570173202421_6328616867636052071_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=a26aad&_nc_ohc=NdYiKVz2oAoAX8yrGwU&_nc_ht=scontent-maa2-1.xx&oh=00_AT9Hc3gJqxCIiNLyXPO93REQwEYsh417lZ8h049t-zaYAw&oe=62B8C87A',

    caption: 'Used Trailers',
    desc: 'New and Used Trailers, Dry Vans and Reefers are at KAL! We are one of the largest authorized dealers for Vanguard trailers in the United States. Flexible in-house financing/leasing options! ',
    time: '7 June',
    follow: 'https://www.instagram.com/accounts/login/?next=/Kalgroup/',
  },
  {
    id: '2',

    img: 'https://scontent-maa2-1.xx.fbcdn.net/v/t39.30808-6/282326995_508309244328514_7772227270459412721_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=a26aad&_nc_ohc=j5OjvCF_VxsAX8VT1NQ&_nc_ht=scontent-maa2-1.xx&oh=00_AT_Mm57H4kgiQBWgIR-8rgbdpOPLWcsH7E20gTNcLsqUtQ&oe=62B7E82F',
    caption: 'KVL Tires',
    desc: 'KAL Partz now offers Amsted Seals wheel-end products; designed to ALL work together - to fit right, install easily and perform at their best! Our team is available to simplify the selection, buying and stocking process for you. Contact us today for more details!',
    time: '21 May',
    follow: 'https://www.instagram.com/accounts/login/?next=/Kalgroup/',
  },

  {
    id: '3',
    img: 'https://scontent-maa2-2.xx.fbcdn.net/v/t39.30808-6/280611353_502279618264810_8376564357247553020_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a26aad&_nc_ohc=NhU5girmI84AX9VFe7q&_nc_ht=scontent-maa2-2.xx&oh=00_AT9afeKMGMpvUEvLseOxCca__QyQKN1JMiYanLlC5HWG4g&oe=62B96310',
    caption: 'KalPartz',
    desc: 'KAL Partz now offers Amsted Seals wheel-end products; designed to ALL work together - to fit right, install easily and perform at their best! Our team is available to simplify the selection, buying and stocking process for you. Contact us today for more details! ',
    time: '12 May',
    follow: 'https://www.instagram.com/accounts/login/?next=/Kalgroup/',
  },
  {
    id: '4',
    img: 'https://scontent-maa2-2.xx.fbcdn.net/v/t39.30808-6/279779116_494988118993960_4082047950062427929_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a26aad&_nc_ohc=xEO95iBWVrgAX-g6VmW&_nc_ht=scontent-maa2-2.xx&oh=00_AT_CFgops2YDtisXcZzClwQXw6lq2CSH4GFSWF4wuwduPw&oe=62B900EC',
    caption: 'Kalway',
    desc: 'Our KALWAY team has designed a freight program for those interested in lease-to-own with GUARANTEED LOADS. Open Enrollment! For more information or to apply please call: 1-800-303-0076',
    time: '2 May',
    follow: 'https://www.instagram.com/accounts/login/?next=/Kalgroup/',
  },
];
export const MapData = [
  {
    id: 0,
    name: 'Canada',
    Sites: [
      {
        id: 0,
        name: 'British Columbia',
        Sites: [
          {
            id: 0,
            name: 'Abbotsford',
            address: '1225, Riverside Road, Abbotsford, BC, Canada',
          },
        ],
      },
      {
        id: 1,
        name: 'Alberta',
        Sites: [
          {
            id: 0,
            name: 'Calgary',
            address: '9115 52St SE Calgary, Alberta, Canada',
          },
        ],
      },
    ],
  },
  {
    id: 1,
    name: 'USA',
    Sites: [
      {
        id: 0,
        name: 'California',
        Sites: [
          {
            id: 0,
            name: 'Fontana',
            address: '10156 Live Oak Ave, Fontana, CA, 92335',
          },
          {
            id: 1,
            name: 'Bakersfield',
            address: '3401 Sillect Ave, Bakersfield, CA 93308',
          },
          {
            id: 2,
            name: 'San Marcos',
            address: '160 Industrial St, San Marcos, CA 92078',
          },
          {
            id: 3,
            name: 'Stockton',
            address:
              '7100 S Harland Rd, Lathrop, Stockton-CA 953307100 S Harland Rd, Lathrop, Stockton-CA 9533',
          },
        ],
      },
      {
        id: 1,
        name: 'Georgia',
        Sites: [
          {
            id: 0,
            name: 'Atlanta',
            address: '3435 Jonesboro Rd Se Atlanta GA 30354',
          },
        ],
      },
      {
        id: 2,
        name: 'Indiana',
        Sites: [
          {
            id: 0,
            name: 'Indianapolis',
            address: '2025 English Ave, Indianapolis, IN, 46201',
          },
        ],
      },
      {
        id: 3,
        name: 'New Jersey',
        Sites: [
          {
            id: 0,
            name: 'Hampton',
            address: '    15 STATE ROUTE 173 Hampton NJ ',
          },
        ],
      },
      {
        id: 4,
        name: 'Arkansas',
        Sites: [
          {
            id: 0,
            name: 'Springdale',
            address: '    1080 W Henri De Tonti Blvd, Springdale, AR, 72762 ',
          },
        ],
      },
      {
        id: 4,
        name: 'Texas',
        Sites: [
          {
            id: 0,
            name: 'Arlington',
            address: '600 109th St, Arlington TX-7601',
          },
        ],
      },
    ],
  },
];
